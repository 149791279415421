<!--  设备管理-备件管理-备件领用-审核 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">申请信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="日期：" class=" ">
                    <el-date-picker v-model="value1" type="date" placeholder="2021-02-02" class="width_180">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="单号：" class=" ">
                    <el-input v-model="formInline.user" placeholder="20210202001" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="申请人：" class=" ">
                    <el-input v-model="formInline.user" placeholder="试用用户" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="领用分类：">
                    <el-select v-model="formInline.region" placeholder="维修" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="仓库：">
                    <el-select v-model="formInline.region" placeholder="五金库" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="关联单号：">
                    <el-input v-model="formInline.user" placeholder="WX-20210202001" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="关联设备：">
                    <el-input v-model="formInline.user" placeholder="ZKM-03-003" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="部门：">
                    <el-select v-model="formInline.region" placeholder="生产部" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="3" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form>

            <div class="box_head1 width_100">出库信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="仓库管理员：" class=" ">
                    <el-input v-model="formInline.user" placeholder="" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="经办人：" class=" ">
                    <el-input v-model="formInline.user" placeholder="" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="领用单位：">
                    <el-input v-model="formInline.user" placeholder="" class="width_180"></el-input>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" style="width: 65%" class="mg_top_20" max-height="250" border>
                <!-- <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_10 "
                @selection-change="handleSelectionChange" max-height="500" border style="width: 65%;"> -->
                <el-table-column type="index" label="序号" width="80"></el-table-column>
                <el-table-column prop="name" label="备件编号" width=""></el-table-column>
                <el-table-column prop="name" label="备件名称" width=""></el-table-column>
                <el-table-column prop="name" label="规格型号" width=""></el-table-column>
                <el-table-column prop="amount1" label="计量单位" width=""></el-table-column>
                <el-table-column prop="amount2" label="当前库存" width=""></el-table-column>
                <el-table-column prop="amount2" label="申请数量" width=""></el-table-column>
                <el-table-column prop="amount2" label="出库数量" width=""></el-table-column>
            </el-table>

            <div class="flex mg_top_30 mg_bottom_30">
                <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">驳回</el-button>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                formInline: {
                    user: '',
                    region: ''
                },
                value: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                formLabelWidth: '110px',
                tableData: [{
                    id: '12987122',
                    name: '王小虎',
                    amount1: '234',
                    amount2: '3.2',
                    amount3: 10
                }, {
                    id: '12987123',
                    name: '王小虎',
                    amount1: '165',
                    amount2: '4.43',
                    amount3: 12
                }, {
                    id: '12987124',
                    name: '王小虎',
                    amount1: '324',
                    amount2: '1.9',
                    amount3: 9
                }, {
                    id: '12987125',
                    name: '王小虎',
                    amount1: '621',
                    amount2: '2.2',
                    amount3: 17
                }, {
                    id: '12987126',
                    name: '王小虎',
                    amount1: '539',
                    amount2: '4.1',
                    amount3: 15
                }]
            }
        },
        mounted() {},
        methods: {
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            },
        }

    }
</script>
<style scoped>
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }
</style>